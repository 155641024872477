import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Badge, Box, Button, CircularProgress, Dialog, Stack, Tab, Typography} from "@mui/material";
import TabellaDomande from "../components/TabellaDomande";
import plesh from '../assets/logo_plesh.png'
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {Delete, History} from "@mui/icons-material";
import {TabellaRifiutate} from "../components/TabellaRifiutate";
import {useTranslation} from "react-i18next";
import {TabellaPassate} from "../components/TabellaPassate";

export const VistaApprovazione = ({azienda}) => {
    const {id} = useParams()
    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione', 'asc'), {})
    const [daApprovare, daApprovareLoading, daApprovareError] = useCollection(firestore.collection('tool-press/' + id + '/daApprovare').orderBy('timestamp','asc'), {})
    const [value, setValue] = React.useState('1');
    const {t,i18n} = useTranslation()
    const [wait, setWait] = useState(false)

    useEffect(() => {
        if(!loading) {
            i18n.changeLanguage(dettagliConferenza.data().language)
        }
    }, [dettagliConferenza])

    const [openRifiutate, setOpenRifiutate] = useState(false)
    const [openPassate, setOpenPassate] = useState(false)

    const handleSetWait = (bool) => {
        setWait(bool)
    }

    const handleSetOpenRifiutate = () => {
        setOpenRifiutate(!openRifiutate)
    }
    const handleSetOpenPassate = () => {
        setOpenPassate(!openPassate)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Dialog open={wait}>
                <CircularProgress/>
            </Dialog>
            <TabellaPassate domandeConferenza={domandeConferenza} handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} id={id} openPassate={openPassate} handleSetOpenPassate={handleSetOpenPassate}/>
            <TabellaRifiutate handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} id={id} openRifiutate={openRifiutate} handleSetOpenRifiutate={handleSetOpenRifiutate} azienda={azienda}/>
            {(!loading && !daApprovareLoading && !domandeLoading) && <div>
                <Stack sx={{p: 2}} direction={"row"} justifyContent={"space-between"}>
                    <Box style={{width: '100%', maxWidth: '10rem'}}>
                        <img style={{width: '100%', borderRadius: '4px'}} src={
                            dettagliConferenza?.data().image === "https://i.vimeocdn.com/video/1189091216-94e083df54558192ca6bf1a4393b8c24baba1b9f386b3e68701ba3507ac61b90-d"
                              ? plesh
                              : dettagliConferenza?.data().image}/>
                    </Box>
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <Button color={'primary'} size={"small"}
                                startIcon={<History/>}
                                onClick={handleSetOpenPassate}
                        >
                            {t('conference.pastQuestions')} {dettagliConferenza.data().domandaAttuale}
                        </Button>

                        {dettagliConferenza.data().approvazione && <Button variant={"outlined"} color={'warning'} startIcon={<Delete/>}
                                                                           onClick={handleSetOpenRifiutate}>{t('conference.refused')}</Button>}
                    </Stack>
                </Stack>

                {loading && <CircularProgress/>}

                {!loading && dettagliConferenza.data().approvazione ?
                    <Box sx={{width: '100%', typography: 'body1'}}>
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="" centered variant={"fullWidth"}>
                                    <Tab label={<div>
                                        {t('conference.toApprove')} <Badge sx={{pl: 2}}
                                                            badgeContent={daApprovare ? daApprovare.docs.length : 0}
                                                            color="primary"/>
                                    </div>} value="1"/>
                                    <Tab label={<div>
                                        {t('conference.approved')} <Badge sx={{pl: 2}}
                                                                          badgeContent={domandeConferenza ? (domandeConferenza.docs.length - dettagliConferenza.data().domandaAttuale) : 0}
                                                            color="primary"/>
                                    </div>} value="2"/>
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {loading && <CircularProgress/>}
                                {(!loading && !daApprovareLoading) &&
                                    <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza}
                                                    domandeConferenza={daApprovare} tipo={'daApprovare'} azienda={azienda}/>
                                }
                            </TabPanel>
                            <TabPanel value="2">
                                {loading && <CircularProgress/>}
                                {(!loading && !domandeLoading) &&
                                    <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza}
                                                    domandeConferenza={domandeConferenza} tipo={'approvate'}
                                                    azienda={azienda}/>
                                }
                            </TabPanel>
                        </TabContext>
                    </Box>
                    :
                    <div>
                        {!loading && !domandeLoading &&
                            <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} azienda={azienda}
                                            domandeConferenza={domandeConferenza}/>
                        }
                    </div>
                }

            </div>}

        </div>
    )
}
