import React, {useEffect, useState} from "react";
import {Avatar, Box, Button, Chip, CircularProgress, Dialog, IconButton, Stack, Typography} from "@mui/material";
import {ArrowBack, ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight, Cancel, Error} from "@mui/icons-material";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import firebase from "firebase/compat/app";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";

export const Clicker = () => {
    const {id} = useParams()
    const [wait, setWait] = useState(false)
    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione', 'asc'), {})
    const [domandeRimanenti, setDomandeRimanenti] = useState(0)

    useEffect(() => {
        if (!loading && !domandeLoading && !domandeError) {
            setDomandeRimanenti(domandeConferenza.docs.length - dettagliConferenza.data().domandaAttuale - 1)
        }
    }, [domandeConferenza, dettagliConferenza])

    const updateDomandaAttuale = async (amount) => {
        setWait(true)
        await axios.put(`${API_URL}/conference/${id}/updateCurrentQuestion`, {amount: amount})
        setWait(false)
    }

    return (
        <Box>
            <Dialog open={wait || loading}>
                <CircularProgress/>
            </Dialog>
            {(!loading && !error && !domandeLoading && !domandeError) &&
                <Stack sx={{height: '100vh', width: '100%'}} direction={"column"}>
                    <Stack sx={{height: '80%', width: '100%'}} direction={'row'}>
                        <Button disabled={dettagliConferenza.data().domandaAttuale === 0} variant={"contained"}
                                color={"error"}
                                sx={{width: '50%'}} onClick={() => updateDomandaAttuale(-1)}>
                            <ArrowBackIos sx={{fontSize: '5rem'}}/>
                        </Button>
                        <Button disabled={domandeConferenza.docs.length < dettagliConferenza.data().domandaAttuale + 1}
                                variant={"contained"} color={"success"} sx={{width: '50%'}}
                                onClick={() => updateDomandaAttuale(1)}>
                            <ArrowForwardIos sx={{fontSize: '5rem'}}/>
                        </Button>
                    </Stack>
                    <Stack sx={{height: '20%', width: '100%'}} direction={'row'} justifyContent={'center'}
                           alignItems={'center'}>
                        <Box>
                            <Chip
                                label={
                                    domandeRimanenti === 0
                                        ? "Last question" : domandeRimanenti > 0 ? "Remaining questions" : "No more questions"}
                                variant={domandeRimanenti > 0 ? "outlined" : "contained"}
                                color={domandeRimanenti === 0 ? "warning" : domandeRimanenti > 0 ? "default" : "error"}
                                avatar={
                                    <Avatar
                                        sx={{bgcolor: (domandeRimanenti) > 0 ? 'success.main' : 'error.main'}}
                                    >
                                        {domandeRimanenti === 0 ? <Error/> : domandeRimanenti > 0 ?
                                            <Typography sx={{color: 'black'}}
                                                        fontWeight={'bold'}>{domandeRimanenti}</Typography> : <Cancel/>}
                                    </Avatar>}/>
                        </Box>
                    </Stack>
                </Stack>}
        </Box>
    )
}
