import React from "react";
import EditQuestion from "../EditQuestion";
import DeleteQuestion from "../DeleteQuestion";
import {Grid, IconButton} from "@mui/material";
import {green, red, yellow} from "@mui/material/colors";
import {ArrowBack, Check, Clear} from "@mui/icons-material";
import {AddAnswer} from "./AddAnswer";
import {useQuery} from "../../custom-hooks/useQuery";

export const Comandi = ({
                            dettagliConferenza,
                            tipo,
                            azienda,
                            row,
                            updatePosition,
                            approvaDomanda,
                            rifiutaDomanda,
                            spostaDaApprovare
                        }) => {
    return (
        tipo !== 'passate' ?
            <Grid item xs={12} sm={4} md={2} justifyContent={{xs: "space-between", sm: "center"}}>
                {/*(!azienda) &&*/
                    <EditQuestion colonne={dettagliConferenza.data().colonne}
                                  domanda={row}
                                  tipo={tipo}
                                  updatePosition={updatePosition}/>}
                {(!dettagliConferenza.data().approvazione || (tipo === 'rifiutate' && !azienda)) &&
                    <DeleteQuestion domanda={row.id}
                                    tipo={tipo}/>}
                {(dettagliConferenza.data().approvazione && tipo !== 'approvate') &&
                    <IconButton
                        sx={{background: green[500], color: 'white', ml: {xs: 1, md: 2}}}
                        onClick={() => approvaDomanda(row, tipo)}
                        color={"success"}
                    >
                        <Check fontSize={"inherit"}/>
                    </IconButton>}
                {(dettagliConferenza.data().approvazione && tipo !== 'rifiutate') &&
                    <IconButton sx={{background: red[500], color: 'white', ml: {xs: 1, md: 2}}}
                                onClick={() => rifiutaDomanda(row, tipo)}
                                color={"error"}
                    >
                        <Clear fontSize={"inherit"}/>
                    </IconButton>}
                {(dettagliConferenza.data().approvazione && tipo === 'rifiutate') &&
                    <IconButton sx={{background: yellow[300], color: 'black', ml: {xs: 0.5, md: 2}}}
                                onClick={() => spostaDaApprovare(row, tipo)}>
                        <ArrowBack fontSize={"inherit"}/>
                    </IconButton>}
                {tipo !== 'rifiutate' && <AddAnswer row={row} tipo={tipo}/>}
            </Grid>
            : null
    )
}
