import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import plesh from "../assets/logo_plesh.png";
import {Box, Button, Card, CircularProgress, IconButton, Slide, Snackbar, Stack} from "@mui/material";
import TabellaDomande from "../components/TabellaDomande";
import {Textfit} from "react-textfit";

import MuiAlert from '@mui/material/Alert';
import {Close, Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const VistaPresentatore = ({tablet}) => {
    const {id} = useParams()
    const {i18n} = useTranslation()
    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione', 'asc'), {})
    const [messages, messageLoading, messageError] = useCollection(firestore.collection('tool-press/' + id + '/messaggi'), {})
    const [messaggio, setMessaggio] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if(!loading) {
            i18n.changeLanguage(dettagliConferenza.data().language)
        }
    }, [dettagliConferenza])

    useEffect(() => {
        if(messages && !messageLoading && !messageError){
            setMessaggio(messages.docs.filter(d => d.id === dettagliConferenza.data().messaggio)[0]?.data().messaggio)
            setOpen(true)
        }
    },[messages, dettagliConferenza])

    return (
        <div>
            <Box p={2} style={{width: '100%', maxWidth: '10rem'}}>
                <img style={{width: '100%', borderRadius: '4px'}} src={
                    dettagliConferenza?.data().image === "https://i.vimeocdn.com/video/1189091216-94e083df54558192ca6bf1a4393b8c24baba1b9f386b3e68701ba3507ac61b90-d"
                      ? plesh
                      : dettagliConferenza?.data().image}/>
            </Box>
            {loading && <CircularProgress/>}
            {!loading &&
                <TabellaDomande dettagliConferenza={dettagliConferenza} tablet={tablet}
                                domandeConferenza={domandeConferenza}/>
            }
            {(messages && !messageError && !messageLoading) &&
                <Slide direction={'up'} in={dettagliConferenza.data().messaggio !== '' && open}>
                    <Box sx={{width: '100%', height: '7rem', color:'white', position:'fixed', bottom:0}}>
                        <Card sx={{width: '98%', margin:'auto', height: '100%'}} variant={'tabletMessage'}>
                            <IconButton size={"large"} sx={{background:'#6864e6 !important', top: '-3rem' ,borderRadius:'0.5rem 0.5rem 0 0', position:'absolute', visibility:'visible !important'}} onClick={() => setOpen(!open)}>
                                {open ? <VisibilityOff/>: <Visibility/>}
                            </IconButton>
                            <Textfit mode={'multi'} className={dettagliConferenza.data().flashMessage && 'flashing'} min={10} max={140} style={{
                                width: '100%',
                                height: '100%',
                                textAlign: 'center',
                                whiteSpace: 'pre-line',
                            }}>
                                {messaggio}
                            </Textfit>
                        </Card>
                    </Box>
                </Slide>
            }
        </div>
    )
}

export default VistaPresentatore
